import React, { useEffect } from "react";

import {
    SEO,
} from "@components";


export default function SlackPage() {
    useEffect(() => {
        window.location.replace("https://app.gather.town/app/qeRzIXydMcZ675oZ/reactconfbr22");
    });

    return (
        <main>
            <SEO title="Gather.Town #reactconfbr22" />
        </main>
    );
}
